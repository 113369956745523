import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import session from '@demre/client-common/src/components/jwt-refresher/session';

Vue.use(Vuex);

const state = {
  resumen: false,
  agrupacionCarreras: [],
  pruebas: [],
  ofertaConfirmada: false,
  nominaPreliminar: false,
  puntajes:{},
};

export default new Vuex.Store({
  strict: true,
  state,
  mutations,
  modules: {
    session,
  }
});
