import Vue from 'vue';
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import graphql from './utils/graphql';
import store from './store';

Vue.use(archivos);

Vue.config.productionTip = false

async function init() {

  const query = {
    query: `query {
      init {
        roles
        universidad {
          codigo
          nombre
          sitioWeb
          sigla
          estadoOferta
          pace
        }
        config {
          matriculaPreliminar {
            limiteBusquedaArchivo
          }
          envioEmailRenuncia
          bloqueoCarrera
          ponderacionMaxima
          puntaje {
            min
            max
            ponderadoMinimo
            promedioMinimoLectMate
          }
          anioProcesoAdmision
        }
        modulos
        accesoEspecial {
          ofertaAcademica
          ofertaPace
          ofertaPedagogia
        }
      }
    }`
  }

  const { init } = await graphql.request(query);
  if (!init) {
    throw new Error('No se pudo recuperar datos, por favor intente más tarde');
  }
  return init;
}

store.dispatch('session/load')
  .then(init)
  .then(async data => {
    const { getPayload } = await import('./utils/utils');
    const { username } = getPayload();
    data.accesoEspecial = {
      'Oferta academica': data.accesoEspecial.ofertaAcademica,
      'Oferta pace': data.accesoEspecial.ofertaPace,
      'Oferta pedagogia': data.accesoEspecial.ofertaPedagogia,
    };
    window.DATA = data;
    window.DATA.profileUrl = `${process.env.VUE_APP_CADES_CLIENT}/profile?appId=${process.env.VUE_APP_CADES_ID}&username=${username}`;
    const ofertaPreliminarVigente = window.DATA.modulos.includes('Nomina preliminar');
    store.commit('setNominaPreliminar', ofertaPreliminarVigente);

    if (window.DATA.universidad) {
      store.commit('setOfertaConfirmada', window.DATA.universidad.estadoOferta == 1);
    }

    store.commit('setPuntajes', window.DATA.config.puntaje);

    const { default: auth } = await import('./plugins/authorized');
    Vue.use(auth);

    const { default: graphql } = await import('./plugins/graphql');
    Vue.use(graphql);

    const { default: asset } = await import('./utils/asset');
    Vue.use(asset);

    const { default: validator } = await import('./utils/validator');
    Vue.use(validator);

    const { default: App } = await import('./App' /* webpackChunkName: 'universidades' */);
    const { default: router } = await import('./router' /* webpackChunkName: 'router' */);

    window.store = store;
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(async err => {
    const mensaje = err.message;
    const { default: Impedimento } = await import('./Impedimento.vue' /* webpackChunkName: 'impedimento' */);
    new Vue({
      vuetify,
      store,
      render: h => h(Impedimento, { props: { mensaje } })
    }).$mount('#app')
  })
