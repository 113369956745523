const findAgrupacion = (state, codigo) => state.agrupacionCarreras.find(agrupacion => agrupacion.codigo == codigo);
export default {
  setResumen: (state, load) => state.resumen = load,
  setAgrupacionCarreras: (state, load) => state.agrupacionCarreras = load,
  enableItemAgrupacionCarrera: (state, codigo) => findAgrupacion(state, codigo).disabled = false,
  disableItemAgrupacionCarrera: (state, codigo) => findAgrupacion(state, codigo).disabled = true,
  setPruebas: (state, load) => state.pruebas = load,
  setOfertaConfirmada: (state, load) => state.ofertaConfirmada = load,
  setNominaPreliminar: (state, active) => state.nominaPreliminar = active,
  setPuntajes: (state, puntajes) => state.puntajes = puntajes,
};



