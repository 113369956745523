import store from '../store';

async function request(req) {
  await store.dispatch('session/ensureSession');

  if (typeof req === 'string') {
    req = {
      query: `query {${req}}`
    };
  }
  const token = window.localStorage.getItem('token');
  let response;
  try {
    response = await fetch(process.env.VUE_APP_SERVER_HOST + '/api/graphql', {
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(req)
    });
  } catch (e) {
    throw new Error('En este momento no podemos responder la solicitud, por favor intenta en unos minutos o comunicate con mesa de ayuda si el problema persiste');
  }

  const data = await response.json();

  if (typeof data.errors !== 'undefined') {
    const msgError = [];
    data.errors.forEach(error => {
      //verificar si es un DemreError
      const code = error.extensions.code
      if (/^E[0-9]{4}[A-Z]$/.test(code)) {
        msgError.push(error.message);
      }
    });
    if (msgError.length) {
      const error = new Error(msgError.join('<br/>'));
      error.isDemreError = true;
      error.original = data.errors;
      throw error;
    }
    throw Error(`Ocurrió un error inesperado. (${response.status})`);
  }
  return data.data;
}

export default {
  request
};
